.clients{padding:20px 0;box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.04);}
.clients-inner {text-align: center;display: flex;align-items: center; gap: 40px;}
.clients h2{font-weight: 700;font-size: 18px;line-height: 38px;color:rgba(0, 0, 0, 1)}
.clients-logos {display: flex;flex-wrap: wrap;justify-content: center;gap: 40px;}
.clients-logos img {max-width: 150px;height: auto;}
@media only screen and (max-width: 1024px) {
.clients-inner{gap: 20px;flex-wrap: wrap;justify-content: center;}

}
@media only screen and (max-width: 480px) {

.clients h2{font-size: 16px;}
.clients-logos{gap:10px}
}
