.common_section.blog_section{padding:60px 0;background: rgba(23, 67, 136, 0.03);}
.blog_inner{padding-top:35px;display: flex;gap:15px;flex-wrap: wrap;justify-content: center;}
.bloglistbox{width:calc(415px - 15px);border:1px solid #E6E6E6;}
.bloglistbox a{text-decoration: none;}
.blogtop {height: 240px;overflow: hidden;}
.blogbottom {display: flex; gap: 10px; flex-direction: column;margin-top: 15px;padding: 0 20px 25px 20px}
.blogbottom span {font-size: 14px;font-weight: 500;line-height: 26px;color:#E52B20;}
.blogbottom .bl_hd {font-size: 20px;font-weight: 600;line-height: 30px;color:#202020;overflow: hidden;text-overflow: ellipsis; display: -webkit-box;-webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.blogbottom .bl_cont {font-size: 14px; font-weight: 400;line-height: 28px;color:#777777;    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;}
.blogtop img {width: 100%;height: 100%;object-fit: cover;transition: 0.4s ease;}
.bloglistbox a:hover img {transform: scale(1.08);}
.bloglistbox a:hover .blogbottom .bl_hd{color:#005b99}
.blog_btm{display: flex;justify-content: center;padding-top: 50px;}
.viewblog_btn{width: 252px;border-radius:60px;border: 1px solid #E52B20;padding:15px;display: flex;align-items: center;justify-content: center;gap: 15px;text-decoration: none;color:#E52B20;    position: relative;font-size: 16px;font-weight: 500;line-height: 20px;}
.viewblog_btn span{height:20px}
.viewblog_btn:after { position: absolute; content: " ";top: 0; left: 0;z-index: -1; width: 100%;height: 100%;transition: all 0.3s ease;-webkit-transform: scale(.1); transform: scale(.1);border-radius: 60px;}
.viewblog_btn:hover:after {background: #E52B20;-webkit-transform: scale(1);transform: scale(1);}
.viewblog_btn:hover:hover {color: #fff;}
.blog_section .common_headingcontent{gap:10px;text-align: center;}
.blog_section .com_desc{font-size: 15px;}
@media only screen and (max-width:991px) {
    .bloglistbox{width:calc(50% - 15px)}
    .blog_btm{padding-top: 30px;}
}
@media only screen and (max-width:568px) {
    .bloglistbox{width:calc(100% - 15px)}
}
@media only screen and (max-width:480px) {
    .blog_inner {padding-top: 20px;}
    .blogbottom .bl_hd {font-size: 18px;line-height: 26px;}
    .blogbottom .bl_cont {font-size: 13px;line-height: 26px;}
}