.banner_section{background:url('../../Helper/images/new/banner-bg.png') no-repeat;background-size:100% 100%;padding:20px 0;min-height: 635px;display: flex;}
.banner_inner{display: flex;align-items: center;height:100%}
.banner_left{display: flex;gap:20px;width:50%;flex-direction: column;}
.homebannersection .banner_left{display: flex;gap:20px;width:50%;flex-direction: column;text-align: left;}
.banner_section .container{display: flex;}
.ban_hd{font-size: 34px;font-weight: 700;line-height: 50px;color:rgba(255, 255, 255, 1)}
.ban_content{font-size: 17px;font-weight: 400;line-height: 32px;color:rgba(255, 255, 255, 1);width:80%}

.lrn_btn{padding: 14px 30px;border: 1px solid rgba(255, 255, 255, 1);background: none;color: rgba(255, 255, 255, 1);cursor: pointer;border-radius: 25px;position: relative;overflow: hidden;font-size: 18px;max-width:max-content;font-weight: 500;text-decoration: none;z-index: 2;gap: 5px;display: flex;}
.lrn_btn:after {position: absolute;content: " ";top: 0;left: 0;z-index: -1; width: 100%; height: 100%;
    transition: all 0.3s ease;-webkit-transform: scale(.1);transform: scale(.1);border-radius: 23px;}
.lrn_btn:hover{color: #222;}
.lrn_btn:hover:after {background: rgba(255, 255, 255, 1);-webkit-transform: scale(1); transform: scale(1);}
.banner_right{position: relative;width: 50%;display: flex;height: 500px;}
.banner_right img{transition: all .5s;}
.banner_fi1{opacity: 0;position: absolute;top:-50px;left:50%;transform: translateX(-50%);animation: moveDown 5s ease-in-out forwards;}
.banner_fi2{opacity: 0;position: absolute;top:36%;right:-15px;animation: moveDown1 10s ease-in-out infinite;animation-delay: 2s;z-index: 1;}
.banner_fi3{opacity: 0;position: absolute;top:10%;left:16%;transform: translateX(-50%);animation: moveDown3 10s ease-in-out infinite;animation-delay: 3s;z-index: 2;}
.banner_fi4{opacity: 0;position: absolute;top:10%;right:-70px;transform: translateX(-50%); animation: moveDown4 10s ease-in-out infinite;animation-delay: 4s;z-index: 3;}
@keyframes moveDown {0% {top: -50px;opacity: 0;}20% {top: -50px;opacity: 0;}50% {top:-10px;opacity: 1;}100% {top:0;opacity: 1;}}
@keyframes moveDown1 {0% {top:15%;opacity: 0;}20% {top: 15%;opacity: 0;}50% {top:36%;opacity:1;}100% {top:36%;opacity: 1;}}
@keyframes moveDown3 {0% {top: -50px;opacity: 0;}20% {top: -50px;opacity: 0;}50% {top:10%;opacity: 1;}100% {top:10%;opacity: 1;}}
@keyframes moveDown4 {0% {top: -10px;opacity: 0;}20% {top: -10px;opacity: 0;}50% {top:10%;opacity: 1;}100% {top:10%;opacity: 1;}}

@media only screen and (max-width:1024px) {
.banner_fi1{height:300px;width:auto}
.banner_fi2{height:100px;width:auto;right:75px}
.banner_fi3{height:90px;width:auto;left:23%}
.banner_fi4{height:50px;width:auto;right:73px}
.banner_right{height:375px}
}
@media only screen and (max-width:991px) {
    .banner_left,.banner_right,.homebannersection .banner_left{width:100%}
    .homebannersection .banner_left{text-align: center;align-items: center;}
    .banner_inner{flex-wrap: wrap;}
    .banner_right{display: none;}
    .banner_section{min-height: inherit;padding:30px 0}
    
}
@media only screen and (max-width:568px) {
.ban_hd br{display: none;}
}
@media only screen and (max-width:480px) {
.banner_left{gap:10px;text-align: center;align-items: center;}
.ban_hd{font-size: 24px;line-height: 34px;}
.ban_content {width: 100%;font-size: 14px;line-height: 28px;}
.lrn_btn {font-size: 16px;padding: 10px 25px;}
}