.product_homlistbg{background: rgba(23, 67, 136, 0.03);}
.product_homlist,.healthmonitorproductlist{padding:0}
.product_homlist.padd-in{padding:60px 0 80px}
.pr_list{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;}
.pr-image-container{flex:1;text-align:center;padding:20px}
.pr-image-container img{max-width:100%;height:auto}
.healthmonitorproductlist .pr_list{overflow: hidden;}
.healthmonitorproductlist .videobxlist {margin-top:-90px;margin-bottom: -90px;}
.pr-content-container{flex:0 0 58%;padding:0 20px;gap:10px;display: flex;flex-direction: column;}
.healthmonitorproductlist .pr-content-container{flex:0 0 45%;}
.pr-content-container .prlist_conthead{color:#222;font-weight: 700;font-size: 27px;line-height: 38px;}
.pr-content-container .prlist_contdesc{color:#777;font-weight: 400;font-size: 16px;line-height: 27px;    margin-bottom: 10px;}
.pr-content-container ul{list-style:none;padding:0;display:flex;flex-wrap: wrap;gap:15px}
.pr-content-container ul li{margin:5px 0;color:#222;padding-left:20px;padding-right:20px;position:relative;font-size: 16px;font-weight: 500; flex: 1 1 calc(50% - 15px); box-sizing: border-box; }
.pr-content-container ul.single_list li{flex: 1 1 calc(100% - 15px);}
.pr-content-container ul li::before{content:"";position:absolute;left:0;background:#174388;border-radius: 100%;width:8px; height:8px; top:9px;transform: translateY(-50%);}
.product_homlistbg{background: rgba(23, 67, 136, 0.03);}
.product_homlist,.healthmonitorproductlist{padding:0}
.product_homlist.padd-in{padding:60px 0 80px}
.order-2{order:2}
.cards_wedo{background: url(../../Helper/images/new/what-we-do-bg.png) no-repeat;width:100%;height:100%;background-size: 100% 100%;}
.cards_outer{display:flex;flex-wrap:wrap;justify-content:space-around;margin-top: 40px;}
.wedo_card{background-color:#fff;border-radius:40px;box-shadow:0 4px 8px rgba(0,0,0,.1);width:100%;max-width:240px;padding:20px;box-sizing:border-box;text-align:center;margin-top: 65px;position: relative;transition: all 0.4s ease;}
 @media only screen and (max-width: 1024px) {
    .common_section,.product_homlist.padd-in{padding:30px 0 40px}
}
@media only screen and (max-width: 991px) {
    .pr-image-container, .healthmonitorproductlist .pr-content-container {flex: auto;width: 100%;}
    .pr-content-container ul li{flex:1 1 calc(100% - 15px);}
    .pr-image-container {order: 1;padding: 0;}
    .pr-content-container {order: 2;flex: 0 0 100%;}
    .order-2 {order: 1;}
    .videobxlist video {max-width: 100%;max-height: 100%;object-fit: cover;}
    
}
@media only screen and (max-width: 768px) {
    .pr_list {flex-direction: column;}
}
@media only screen and (max-width: 568px) { 
    .videobxlist {max-width: 100%;max-height: 100%;}
    .pr-content-container .prlist_conthead {font-size: 20px;line-height: 32px;}
    .pr-content-container .prlist_contdesc {font-size: 14px;margin-bottom: 0;}
    .pr-content-container ul li {font-size: 14px;}
    .videobxlist video{height: 300px}
    .healthmonitorproductlist .videobxlist {margin-top: -40px;margin-bottom: -20px;}
}
@media only screen and (max-width: 480px) { 
    .pr-content-container ul{gap: 10px;}
    
   
}